<template>
  <div class="work-list">
    <WorkPreview v-for="work in workList" :key="work.id" :data="work" />
  </div>
</template>

<script>
import workList from "../work-list";
import WorkPreview from "./WorkPreview";

export default {
  data() {
    return {
      workList,
    };
  },
  components: {
    WorkPreview,
  },
};
</script>

<style lang="scss" scoped>
.work-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  margin: -10px;
  > * {
    padding: 10px;
  }
}
</style>
