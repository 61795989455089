<template>
  <router-link :to="`/work/${data.id}`" class="work-item">
    <!-- {{ data.name }} -->
    <lazy-image :src="data.previewImage" :alt="data.name" />
  </router-link>
</template>

<script>
import LazyImage from "@/components/LazyImage";

export default {
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  components: {
    LazyImage,
  },
};
</script>

<style lang="scss" scoped>
.work-item {
  width: 25%;
  filter: grayscale(1);
  transition: filter 300ms linear;

  @media screen and (max-width: 720px) {
    width: 50%;
  }

  /deep/ .image-wrapper {
    overflow: hidden;
  }

  &:hover,
  &:focus {
    filter: grayscale(0);

    /deep/ img {
      transform: scale(1.1);
    }
  }
}
</style>
