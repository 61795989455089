<template>
  <page-wrapper class="work">
    <h1>Things I have made</h1>
    <work-list />
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import WorkList from "@/components/WorkList";

export default {
  metaInfo: {
    title: "Work",
  },
  components: {
    PageWrapper,
    WorkList,
  },
};
</script>

<style lang="scss" scoped>
.work {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
